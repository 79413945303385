<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '500px' }"
    header="Pago de Clientes"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12" v-if="cargandoComponente">
      <div class="grid" v-if="permitirPago">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for=""
              ><strong>FACTURA NRO.: </strong>{{ data_pago.venta_id }}</label
            >
          </div>
          <div class="field col-12 md:col-3">
            <label for=""
              ><strong>TOTAL VENTA: </strong>
              {{ convertirNumeroGermanicFormat(data_pago.total_venta) }}
              Bs.</label
            >
          </div>
          <div class="field col-12 md:col-3">
            <label for=""
              ><strong>A CUENTA: </strong>
              {{ convertirNumeroGermanicFormat(suma_acuenta_total) }} Bs.</label
            >
          </div>
          <div class="field col-12 md:col-3">
            <label for=""
              ><strong>SALDO: </strong>
              {{
                convertirNumeroGermanicFormat(
                  data_pago.saldo - a_cuenta_efectivo - a_cuenta_metodo_pago
                )
              }}
              Bs.</label
            >
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <label for="metodo_pago"
              ><strong>M&Eacute;TODO DE PAGO:</strong></label
            >
            <Dropdown
              id="metodo_pago"
              v-model="metodo_pagoSelect"
              :options="metodo_pagos"
              optionLabel="nombre"
              class="w-full"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-4" v-if="metodo_pagoSelect != 1">
            <label for=""><strong>MONTO:</strong></label>
            <InputNumber
              v-model="a_cuenta_metodo_pago"
              :minFractionDigits="2"
              mode="decimal"
              showButtons
              :inputClass="'text-right'"
              :min="0"
              :max="parseFloat(data_pago.saldo - a_cuenta_efectivo)"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for=""
              ><strong>EFECTIVO: </strong
              ></label
            >
            <InputNumber
              v-model="a_cuenta_efectivo"
              :minFractionDigits="2"
              mode="decimal"
              showButtons
              :inputClass="'text-right'"
              :min="0"
              :max="parseFloat(data_pago.saldo - a_cuenta_metodo_pago)"
            />
          </div>
          <div class="field col-12 md:col-12">
            <label for=""><strong>OBSERVACI&Oacute;N:</strong></label>
            <Textarea
              v-model="data_pago.observacion"
              :autoResize="true"
              placeholder="observacion"
            />
          </div>
        </div>
      </div>
      <div class="grid" v-else>
        <Message class="col-12" :severity="'info'" :closable="false">
          <div class="flex align-items-center justify-content-center">
            <span> PARA REALIZAR PAGOS DEBE ABRIR LA CAJA </span>
            <Button
              label="APERTURAR CAJA"
              class="p-button-success p-button-lg ml-3"
              icon="pi pi-lock-open"
              v-tooltip.top="'Abrir Caja'"
              @click="this.$router.push({ name: 'caja' })"
            />
          </div>
        </Message>
      </div>
    </div>
    <div v-else>
      <div class="grid">
        <h3 class="col-12">Verificando caja ...</h3>
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          class="col-12"
        />
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          >Campos Requeridos: <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="guardarPagoClientes"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import PagoClienteService from "@/service/PagoClienteService.js";
import MetodoPagoService from "@/service/MetodoPagoService.js";
import CajaService from "@/service/CajaService";

export default {
  name: "PagoCliente",
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pago_cliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_pago: this.pago_cliente,
      enviado: false,
      errors: {},
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      a_cuenta_metodo_pago: 0,
      a_cuenta_efectivo: 0,
      saldo: 0,
      permitirPago: false,
      cargandoComponente: false,
      observacion: "",
    };
  },
  pagoClienteService: null,
  metodoPagoService: null,
  cajaService: null,
  created() {
    this.pagoClienteService = new PagoClienteService();
    this.metodoPagoService = new MetodoPagoService();
    this.cajaService = new CajaService();
  },
  mounted() {
    this.getMetodoPagos();
    this.verificarCajaAbierta();
  },
  computed: {
    suma_acuenta_total() {
      return (
        parseFloat(this.data_pago.a_cuenta_efectivo) +
        parseFloat(this.data_pago.a_cuenta_metodo_pago)
      );
    },
  },
  methods: {
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirPago = true;
        } else {
          this.permitirPago = false;
        }
        this.cargandoComponente = true;
      });
    },
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    guardarPagoClientes() {
      this.enviado = true;
      // Preparo los datos para enviar
      let datos = {
        id: this.data_pago.id,
        metodo_pago_id: this.metodo_pagoSelect,
        a_cuenta_metodo_pago: this.a_cuenta_metodo_pago,
        a_cuenta_efectivo: this.a_cuenta_efectivo,
        venta_id: this.data_pago.venta_id,
        observacion: this.observacion,
      };

      // VALIDO LOS MONTOS INTRODUCIDOS QUE SEAN MAYORES A CERO
      if (datos.a_cuenta_metodo_pago <= 0 && datos.a_cuenta_efectivo <= 0) {
        this.enviado = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar un monto mayor a cero!",
          life: 3000,
        });
        return;
      }
      this.pagoClienteService.actualizoPagoCliente(datos).then(() => {
        this.enviado = false;
        this.$emit("actualizarListado");
        this.$emit("closeModal");
        this.closeModal();
      });
    },
    closeModal() {
      this.$emit("closeModal");
      this.enviado = false;
      this.metodo_pagoSelect = 1;
      this.a_cuenta_metodo_pago = 0;
      this.a_cuenta_efectivo = 0;
      this.saldo = 0;
      this.observacion = "";
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    pago_cliente(val) {
      this.data_pago = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
